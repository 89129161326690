import api from "./api";

export const fetchProviders = async () => {
  const response = await api.get(`/provider`);
  return response.data;
}

export const updateProvider = async (id, data) => {
  const response = await api.patch(`/provider/${id}`, data);
  return response.data;
}
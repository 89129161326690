import { fetchLender, fetchSingleLender } from '@/api/lendersApi';
import PageHeader from '@/components/PageHeader';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { Oval } from 'react-loader-spinner';

export const LenderInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const lender = useQuery({
    queryKey: ['lender', { id }],
    queryFn: fetchSingleLender,
    onSuccess: (data) => {}
  });

  const handleReturn = () => {
    window.history.back();
  };

  const detail = lender?.data?.data;

  if (lender.isFetching) {
    return (
      <LoaderView>
        <div className="loading-view">
          <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
        </div>
      </LoaderView>
    );
  }

  return (
    <>
      <LenderInfoView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Individual Name</h3>
                <p>{detail?.name || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Company Name</h3>
                <p>{detail?.company || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>E-mail address</h3>
                <p>{detail?.email || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Total Offers Amount</h3>
                <p>₦{numberWithCommas(detail?.totalOffers || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Total Closed Amount</h3>
                <p>₦{numberWithCommas(detail?.totalClosed || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Total Disbursed Amount</h3>
                <p>₦{numberWithCommas(detail?.totalDisbursed || '00.00')}</p>
              </div>
            </div>
          </FormData>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Transaction Count</h3>
                <p>{numberWithCommas2(detail?.count || '0')}</p>
              </div>
            </div>
            {/* <div className="item">
              <div className="label-view">
                <h3>Interest Rate (%)</h3>
                <p>{numberWithCommas2(detail?.interests || '0')}%</p>
              </div>
            </div> */}
            <div className="item">
              <div className="label-view">
                <h3>Lender's Rate</h3>
                <p>{numberWithCommas2(parseInt(detail?.takeRate || '0'))}%</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Total Earnings</h3>
                <p>₦{numberWithCommas(detail?.lenderEarnings || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>BillBoxx Earnings</h3>
                <p>₦{numberWithCommas(detail?.billboxxEarnings || '00.00')}</p>
              </div>
            </div>
          </FormData>
        </DataView>
      </LenderInfoView>
      {/* <Tabs /> */}
    </>
  );
};

const LoaderView = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'ACTIVE':
        return '#16A34A';
      case 'inactive':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const LenderInfoView = styled.div`
  /* width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%; */

  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
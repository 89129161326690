import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import styled from 'styled-components';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const options = {
  cMapUrl: '/cmaps/'
};

const FileViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (url.includes('.docx')) {
    return (
      <PDFVieww>
        <DocViewer
          documents={[{ uri: url }]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
          className="my-doc-viewer-style"
        />
      </PDFVieww>
    );
  }

  return (
    <PDFVieww>
      <PDFViewHeader>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <div className="paginate">
          <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
            Previous
          </button>
          <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
            Next
          </button>
        </div>
      </PDFViewHeader>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => <p>{error.message}</p>}
        options={options}>
        <Page pageNumber={pageNumber} />
      </Document>
    </PDFVieww>
  );
};

export default FileViewer;

const PDFVieww = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;

  .my-doc-viewer-style {
  }

  #react-doc-viewer {
    height: 100%;
    width: 100%;
  }

  .react-pdf__Document {
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .react-pdf__Page {
    max-width: calc(100% - 1em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 1em;
    margin: 0 auto;
  }

  .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }

  .react-pdf__message {
    padding: 1em;
    color: white;
  }

  .pdf {
    width: 100%;
    height: 100%;
  }
`;

const PDFViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  /* background-color: ${(props) => props.theme.layer4};
  border-bottom: 1px solid ${(props) => props.theme.border}; */
  width: 100%;

  p {
    font-size: 0.875rem;
  }

  .paginate {
    display: flex;
    gap: 1rem;
  }

  button {
    cursor: pointer;
  }
`;

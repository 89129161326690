import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { deactivateUser } from '@/api/userApi';
import { fetchAccount } from '@/api/accountsApi';
import { numberWithCommas } from '@/utils/helpers';
import { fetchSingleDisbursement } from '@/api/lendersApi';
import { formatInTimeZone } from 'date-fns-tz';
import TableLoader from '@/loaders/TableLoader';
import { ReactComponent as FileIcon } from '@/assets/icons/file.icon.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import FileModal from './FileModal';

function DisbursementModal({ showModal, setShowModal, selectedItem }) {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const [showSupportingDocs, setShowSupportingDocs] = useState(false);

  const disbursement = useQuery({
    queryKey: ['disbursement', selectedItem],
    queryFn: fetchSingleDisbursement
  });

  const detail = disbursement.data?.data;

  const handleFileDownload = async (url, filename) => {
    console.log('Attempting to download file:', url);
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];

    try {
      setLoading(true);
      const res = await axios.get(url, { responseType: 'blob' });
      fileDownload(res.data, newFileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadOfferLetter = () => {
    handleFileDownload(detail?.offerLetter, 'Offer-letter');
  };

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
  ).toString();

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            {disbursement.isFetching ? (
              <LoaderView>
                <div className="loading-view">
                  <Oval color="#92C22C" width={50} height={50} strokeWidth={5} />
                </div>
              </LoaderView>
            ) : (
              <ModalContent>
                <div className="header">
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>
                <ContentView>
                  <div className="content">
                    <div className="documents">
                      <h3>Documents</h3>

                      <div className="documents-container">
                        {detail?.supportingDocument?.map((doc, i) => {
                          return (
                            <div className="document">
                              <div className="icon">
                                <FileIcon />
                              </div>
                              <div className="details">
                                <p className="name">{doc?.documentType || '-'}</p>
                                <div className="btn-text">
                                  <button
                                    onClick={() => {
                                      setCurrentIndex(i);
                                      setShowSupportingDocs(true);
                                    }}>
                                    View
                                  </button>
                                  {/* <button
                                  className="download"
                                  onClick={() => downloadSupportingDoc(i)}>
                                  Download
                                </button> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="document">
                          <div className="icon">
                            <FileIcon />
                          </div>
                          <div className="details">
                            <p className="name">Offer Letter</p>

                            <div className="btn-text">
                              <button
                                onClick={() => {
                                  setShowOfferLetter(true);
                                }}>
                                View
                              </button>
                              <button className="download" onClick={downloadOfferLetter}>
                                {loading ? (
                                  <Oval
                                    height={10}
                                    width={10}
                                    color="#92C22C"
                                    secondaryColor="#92C22C"
                                  />
                                ) : (
                                  'Download'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="offer-letter">
                    {detail?.offerLetter ? (
                      <Document file={detail?.offerLetter}>
                        <Page width={416} renderTextLayer={false} pageNumber={1} />
                      </Document>
                    ) : (
                      <div className="empty">
                        <p>No offer letter to display</p>
                      </div>
                    )}
                  </div> */}

                    <DataView>
                      <FormData>
                        <div className="title">
                          <h2>View Details</h2>
                        </div>

                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Offer Id</h3>
                            <p>{detail?.offerId || '-'}</p>
                          </div>
                          <div className="label-view">
                            <h3>Lender</h3>
                            <p>{detail?.lender || '-'}</p>
                          </div>
                        </div>
                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Customer</h3>
                            <p>{detail?.customer || '-'}</p>
                          </div>
                          <div className="label-view">
                            <h3>Type</h3>
                            <p>
                              {detail?.type
                                .toLowerCase()
                                .replace(/_/g, ' ')
                                .replace(/\b\w/g, (char) => char.toUpperCase()) || '-'}
                            </p>
                          </div>
                        </div>
                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Tenure</h3>
                            <p>{detail?.tenure || '0'} Days</p>
                          </div>
                          <div className="label-view">
                            <h3>Facility</h3>
                            <p>₦{numberWithCommas(detail?.facility || '00.00')}</p>
                          </div>
                        </div>
                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Revenue</h3>
                            <p>₦{numberWithCommas(parseInt(detail?.revenue || '00.00'))}</p>
                          </div>
                          <div className="label-view">
                            <h3>Repayment</h3>
                            <p>₦{numberWithCommas(detail?.repayment || '00.00')}</p>
                          </div>
                        </div>
                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Amount Paid</h3>
                            <p>₦{numberWithCommas(parseInt(detail?.amountPaid || '00.00'))}</p>
                          </div>
                          <div className="label-view">
                            <h3>Balance Due</h3>
                            <p>₦{numberWithCommas(parseInt(detail?.balanceDue || '00.00'))}</p>
                          </div>
                        </div>
                        <div className="item-wrapper">
                          <div className="label-view">
                            <h3>Due Date</h3>
                            <p>
                              {detail?.dueDate
                                ? formatInTimeZone(new Date(detail?.dueDate), 'UTC', 'do MMM, yyyy')
                                : '--'}
                            </p>
                          </div>
                          <div className="label-view last">
                            <h3>Date Disbursed</h3>
                            <p>
                              {detail?.disbursementDate
                                ? formatInTimeZone(
                                    new Date(detail?.disbursementDate),
                                    'UTC',
                                    'do MMM, yyyy'
                                  )
                                : '--'}
                            </p>
                          </div>
                        </div>
                        {/* <div className="item-wrapper">
                        <div className="label-view last">
                          <h3>Date Closed</h3>
                          <p>
                            {detail?.closedDate
                              ? formatInTimeZone(
                                  new Date(detail?.disbursementDate),
                                  'UTC',
                                  'do MMM, yyyy'
                                )
                              : '--'}
                          </p>
                        </div>
                        <div></div>
                      </div> */}
                      </FormData>
                    </DataView>
                  </div>
                </ContentView>
              </ModalContent>
            )}
          </motion.div>
        </ModalView>
      )}

      {showSupportingDocs && currentIndex !== -1 && (
        <FileModal showModal={showSupportingDocs}>
          <div className="header">
            <button type="button" onClick={() => setShowSupportingDocs(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            {/* <DocViewer
              style={{ width: 500, height: 600 }}
              pluginRenderers={DocViewerRenderers}
              config={{ header: { disableHeader: true } }}
              documents={[{ uri: detail?.supportingDocuments[currentIndex]?.fileUrl }]}
            /> */}

            {detail?.supportingDocument[currentIndex]?.fileUrl?.includes('.docx') && (
              <iframe
                width="100%"
                height="100%"
                src={`https://docs.google.com/gview?url=${detail?.supportingDocument[currentIndex]?.fileUrl}&embedded=true`}></iframe>
            )}

            {detail?.supportingDocument[currentIndex]?.fileUrl?.includes('.pdf') && (
              <Document file={detail?.supportingDocument[currentIndex]?.fileUrl}>
                <Page width={510} renderTextLayer={false} pageNumber={1} />
              </Document>
            )}

            {detail?.supportingDocument[currentIndex]?.fileUrl?.includes('.png') ||
              detail?.supportingDocument[currentIndex]?.fileUrl?.includes('.jpg') ||
              (detail?.supportingDocument[currentIndex]?.fileUrl?.includes('.jpeg') && (
                <img
                  src={detail?.supportingDocument[currentIndex]?.fileUrl}
                  alt="Supporting Document"
                  className="img"
                />
              ))}

            {!detail?.supportingDocument[currentIndex]?.fileUrl && <p>No File to display</p>}
          </div>
        </FileModal>
      )}

      {showOfferLetter && (
        <FileModal showModal={showOfferLetter}>
          <div className="header">
            <button type="button" onClick={() => setShowOfferLetter(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">
            {detail?.offerLetter ? (
              // <DocViewer
              //   style={{ width: 500, height: 600 }}
              //   pluginRenderers={DocViewerRenderers}
              //   config={{ header: { disableHeader: true } }}
              //   documents={[{ uri: detail?.offerLetter }]}
              // />
              <Document file={detail?.offerLetter}>
                <Page width={510} renderTextLayer={false} pageNumber={1} />
              </Document>
            ) : (
              <p>No File to display</p>
            )}
          </div>
        </FileModal>
      )}
    </AnimatePresence>
  );
}

export default DisbursementModal;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#16A34A';
      case 'inactive':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .loading-view {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 60vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    margin: 0 auto;
    /* display: flex; */
    gap: 4rem;
    /* justify-content: center; */

    .documents {
      margin-bottom: 2rem;
      max-width: 100%;

      .documents-container {
        max-width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 1.2rem;
        flex-wrap: wrap;

        .document {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 1rem 0.75rem;
          border-radius: 15px;
          background-color: ${({ theme }) => theme.colors.document};

          .details {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            .name {
              font-size: 10px;
              font-weight: 600;
              margin-bottom: 0;
              text-transform: capitalize;
              color: ${({ theme }) => theme.colors.secondary};
            }

            .size {
              font-size: 10px;
              font-weight: 400;
              color: ${({ theme }) => theme.colors.body};
            }
          }

          .btn-text {
            display: flex;
          }

          .download {
            /* color: ${({ theme }) => theme.colors.secondary}; */
          }

          button {
            font-size: 10px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.primary};
            background-color: none;
            border-radius: 15px;
            padding: 0.25rem 0.5rem;
            border: 1px solid ${({ theme }) => theme.colors.primary};
            border: none;
          }
        }
      }
    }

    .offer-letter {
      width: 60%;

      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
        border: 1px solid ${(props) => props.theme.colors?.line};
      }
    }

    .icon {
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.darkGray};
      margin-bottom: 20px;
      line-height: 19px;
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: #fdebeb;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    .btn {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;

      .delete {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.error_200};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border: 1px solid ${(props) => props.theme.colors?.info};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.info};
        font-weight: 500;
      }
    }
  }
`;

const DataView = styled.div`
  width: 80%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 5px 20px;

  .title {
    /* margin-top: 2rem; */
    margin-bottom: 1rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .item-wrapper {
    width: 100%;
    display: flex;
    gap: 7rem;
    justify-content: space-between;

    .label-view {
      flex: 1;
      /* width: 70%; */
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

      .last {
        width: 50%;
      }

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;

      .copy-view {
        width: 120px;
        display: flex;
        align-items: center;

        button,
        a {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: ${({ theme }) => theme?.colors?.info};
          font-size: 0.875rem;

          svg {
            margin-left: 5px;
            width: 16px;
            height: 16px;

            path {
              stroke: ${({ theme }) => theme?.colors?.info};
            }
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

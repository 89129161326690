import PageHeader from '@/components/PageHeader';
import React from 'react';
import styled from 'styled-components';
import { LenderInfo } from './components/details/LenderInfo';
import { Tabs } from './components/Tabs';

export const LenderDetails = () => {
  return (
    <LenderDetailsView>
      <PageHeader title="Lenders Details" subtitle="View and manage a Lender" />
      <LenderInfo />
      <Tabs />
    </LenderDetailsView>
  );
};

const LenderDetailsView = styled.div`
  width: 100%;
  height: 100%;
`;

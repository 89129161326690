import { styled } from 'styled-components';

export const SettingsView = styled.div`
  width: 82%;
  padding: 30px 30px 30px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 83px;
  overflow-x: hidden;
  margin-left: 18%;
`;

export const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

export const TabContent = styled.div`
  width: 100%;
`;
import useOnClickOutside from '@/hooks/useClickOutside';
import useDebounce from '@/hooks/useDebounce';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchAssignedLenders } from '@/api/lendersApi';
import { useSortableData } from '@/hooks/useSortableData';
import TableLoader from '@/loaders/TableLoader';
import { Oval } from 'react-loader-spinner';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { formatInTimeZone } from 'date-fns-tz';
import TableRowsSelect from '@/components/TableRowSelect';
import { useSearchParams } from 'react-router-dom';

export const AssignedLenders = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const debouncedsearch = useDebounce(search, 500);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = parseInt(searchParams.get('limit')) || 10;
  const PAGE_LIMIT = 10;

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const assignedLenders = useQuery({
    queryKey: ['assignedLenders', { page, limit, search: debouncedsearch }],
    queryFn: fetchAssignedLenders
  });

  const { items, requestSort, sortConfig } = useSortableData(assignedLenders.data?.data?.lenders || []);

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ tab: 'table', view: 'assigned-lenders', page, limit: value });
  };

  //Paginate
  const fetchNextPage = () => {
    const totalPage = Math.ceil(assignedLenders.data?.data?.meta?.totalItems / limit);
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const fetchPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  if (assignedLenders.isFetching && !search) {
    return <TableLoader />;
  }

  return (
    <AssignedView>
      <TableHeader>
        <SearchView>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search assignedLenders"
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value.trim());
            }}
          />
          {assignedLenders.isFetching && search && <Oval width={20} height={20} strokeWidth={4} />}
        </SearchView>
        <FilterView>
          <button className="export" onClick={() => setShowExportMenu(true)}>
            <ArrowIcon /> Export
          </button>

          <button className="filter" onClick={() => setShowFilterMenu(true)}>
            <FilterIcon /> Filter
          </button>
        </FilterView>
      </TableHeader>
      <TableHead>
        <div className="type">
          <span>Company Name</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="type">
          <span>Name</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="tenure">
          <span>Type</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div>
          <span>Interest</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="">
          <span>Tenure</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div>
          <span>Take Rate</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="">
          <span>Date Assigned</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="">
          <span>Date Created</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        {/* <div className="actions"></div> */}
      </TableHead>
      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className="type">
                <span>{item?.lender || '-'}</span>
              </div>
              <div className="type">
                <span>{item?.name || '-'}</span>
              </div>
              <div className="tenure">
                <span>
                  {item?.type
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase() || '-')}
                </span>
              </div>
              <div className="">
                <span>{numberWithCommas2(item?.interest || '0')}%</span>
              </div>
              <div className="">
                <span>{item?.tenure || '-'} Days</span>
              </div>
              <div className="">
                <span>{numberWithCommas2(parseInt(item?.takeRate) || '0')}%</span>
              </div>
              <div className="">
                <span>
                  {formatInTimeZone(new Date(item?.dateAssigned || '-'), 'UTC', 'do MMM, yyy')}
                </span>
              </div>
              <div className="">
                <span>
                  {formatInTimeZone(new Date(item?.dateCreated || '-'), 'UTC', 'do MMM, yyy')}
                </span>
              </div>
              {/* <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                ===== Dropdown Menu ======
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          setSelectedItem(item);
                        }}>
                        <span>View Disbursement</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div> */}
            </div>
          );
        })}
      </TableBody>

      {/* ===== Pagination ====== */}
      {assignedLenders?.data?.data?.meta?.totalItems > 0 && (
        <PaginationView>
          <TableRowsSelect onChange={handleRowsPerPageChange} />
          <div className="paginate">
            <button className="prev" onClick={fetchPrevPage}>
              <PrevIcon />
            </button>
            <span>
              Page {page} of {Math.ceil(assignedLenders?.data?.data?.meta?.totalItems / limit)}
            </span>
            <button className="next" onClick={fetchNextPage}>
              <NextIcon />
            </button>
          </div>
        </PaginationView>
      )}
    </AssignedView>
  );
};

const AssignedView = styled.div`
  width: 100%;
  height: 100%;
  /* margin-top: 80px; */
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return '#16A34A';
    case 'approved':
      return '#16A34A';
    case 'paid':
      return '#16A34A';
    case 'OFFER':
      return '#F4B740';
    case 'ready':
      return '#F4B740';
    case 'pending':
      return '#F4B740';
    case 'pending-approval':
      return '#F4B740';
    case 'draft':
      return '#F4B740';
    case 'CLOSED':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
  margin-top: 20px;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const FilterView = styled.div`
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  /* border-top: 1px solid ${({ theme }) => theme.colors.line}; */

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .type {
    flex: 1.5;
  }

  .actions {
    flex: 0.3;
  }

  .date {
    flex: 0.7;
  }

  .tenure {
    flex: 1;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .type {
      flex: 1.5;
    }

    .date {
      flex: 0.7;
    }

    .tenure {
      flex: 1;
    }

    .email {
      flex: 1;
      flex-wrap: wrap;

      span {
        text-transform: none;
      }
    }

    .actions {
      flex: 0.3;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 2rem 0;

    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;

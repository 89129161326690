import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AccessDenied } from '@/assets/icons/error-access.svg';
import Button from '@/components/Button';
import { CreateTermsModal } from './CreateTermsModal';

export const TermsEmptyState = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <EmptyView>
        <AccessDenied />
        <div className="text">
          <h1>Oops!</h1>
          <p>You have not created any terms</p>
        </div>

        <Button 
          width="22%" 
          type="button"
          onClick={() => setShowCreateModal(true)}
        >
          Create Terms
        </Button>
      </EmptyView>

      <CreateTermsModal 
        showModal={showCreateModal} 
        setShowModal={setShowCreateModal} 
      />
    </>
  );
};

const EmptyView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5rem auto;
  height: 100%;
  width: 100%;

  .text {
    margin-top: -2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;

import api from "./api"

// export const fetchLenders = async () => {
//   const response = await api.get(`/lender`);
//   return response.data;
// }


export const fetchLender = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/lender/${id}`);
  return response.data;
}

export const makeLender = async (data) => {
  const response = await api.patch(`/lender/add`, data);
  return response.data;
}

export const removeLender = async (data) => {
  const response = await api.patch(`/lender/remove`, data);
  return response.data;
}

export const updateDiscount = async (data) => {
  const response = await api.patch(`/lender/set-discount-fee`, data);
  return response.data;
}

{/* ===== Lender Terms ====== */}
export const createLenderTerms = async (data) => {
  const response = await api.post(`/lender/term/config`, data);
  return response.data;
}

export const assignLenders = async (data) => {
  const response = await api.post(`/lender/term/add`, data);
  return response.data;
}

export const updateLenderTerms = async ( id, data) => {
  const response = await api.patch(`/lender/term/config/${id}`, data);
  return response.data;
}

export const deleteTerms = async (id) => {
  const response = await api.delete(`/lender/term/config/${id}`);
  return response.data;
}

export const fetchLendersTerms = async () => {
  const response = await api.get(`/lender/terms/config`);
  return response.data;
}

export const setTakeRate = async (data) => {
  const response = await api.patch(`/lender/take/rate`, data);
  return response.data;
}

export const fetchLenders = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `lender/type/lenders?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchAssignedLenders = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `lender/assigned/terms?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchOptions = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `lender/type/lenders?page=${page}&limit=${limit}`;
  const response = await api.get(url);
  return response.data;
};

export const fetchLenderActivity = async ({ queryKey }) => {
  const [, { id, page, limit }] = queryKey;
  const response = await api.get(`/lender/${id}/activity?page=${page}&limit=${limit}`);
  return response.data;
}

export const fetchLenderInflows = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/lender/${id}/inflows`);
  return response.data;
}

export const fetchLoanRequests = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/lender/requests/all?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  
  const response = await api.get(url);
  return response.data;
}

export const fetchLoanInterests = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/lender/interests/all?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchLenderDisbursements = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `lender/type/disbursement?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchStats = async () => {
  let url = `lender/stats/type`;

  const response = await api.get(url);
  return response.data;
}

export const fetchSingleDisbursement = async ({ queryKey }) => {
  const [, id] = queryKey;
  
  if (id) {
    const response = await api.get(`lender/single/disbursement/${id}`);
    return response.data;
  } else {
    throw new Error('No ID provided');
  }
}

export const fetchLenderActivities = async ({ queryKey }) => {
  const [, { page, limit, search , orderBy, fetch }] = queryKey;
  let url = `lender/type/activities?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchSingleActivity = async ({ queryKey }) => {
  const [, id] = queryKey;
    
  if (id) {
    const response = await api.get(`lender/single/activity/${id}`);
    return response.data;
  } else {
    throw new Error('No ID provided');
  }
};

export const fetchLenderEarnings = async ({ queryKey }) => {
  const [, { page, limit, search , orderBy, fetch }] = queryKey;
  let url = `lender/type/earning?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchSingleEarning = async ({ queryKey }) => {
  const [, id] = queryKey;
  
  if (id) {
    const response = await api.get(`lender/single/earning/${id}`);
    return response.data;
  } else {
    throw new Error('No ID provided');
  }
};

export const fetchLenderHistories = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `lender/type/history?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchSingleHistory = async ({ queryKey }) => {
  const [, id] = queryKey;

  if (id) {
    const response = await api.get(`lender/single/history/${id}`);
    return response.data;
  } else {
    throw new Error('No ID provided');
  }
};


{/* ===== Lender Details ====== */}
export const fetchSingleLender = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/lender/single/lenders/${id}`);
  return response.data;
} 

export const fetchActivity = async ({ queryKey }) => {
  const [, { id, page, limit, search }] = queryKey;
  let url = `/lender/details/${id}/activity?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchInflow = async ({ queryKey }) => {
  const [, { id, page, limit, search }] = queryKey;
  let url = `/lender/details/${id}/inflows?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchRevenue = async ({ queryKey }) => {
  const [, { id, page, limit, search }] = queryKey;
  let url = `/lender/details/${id}/revenue?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchHistory = async ({ queryKey }) => {
  const [, { id, page, limit, search }] = queryKey;
  let url = `/lender/details/${id}/history?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}
import { EmptyState } from '@/components/EmptyState';
import PageHeader from '@/components/PageHeader';
import { usePermissions } from '@/hooks/usePermissions';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import LendersTable from './components/LendersTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LenderDisbursement } from './components/tables/LenderDisbursement';
import { LenderActivities } from './components/tables/LenderActivities';
import { LenderEarnings } from './components/tables/LenderEarnings';
import { LenderHistory } from './components/tables/LenderHistory';
import { LendersTabs } from './components/LendersTabs';
import { useQuery } from '@tanstack/react-query';
import { fetchStats } from '@/api/lendersApi';
import { numberWithCommas } from '@/utils/helpers';

export const Lenders = () => {
  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canReadLenders = hasPermission('lender', 'read');

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=table&view=lenders-table');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=table&view=lenders-table');
    }
  }, [searchParams, navigate, setSearchParams]);

  const stats = useQuery({
    queryKey: ['stats'],
    queryFn: fetchStats
  });

  const stat = stats?.data?.data;

  const tabs = [
    {
      name: 'Lenders',
      path: 'table&view=lenders-table',
      value: 'table',
      total: `${stat?.lenders?.count}`,
      price: `${stat?.lenders?.amount}`
    },
    {
      name: 'Activities',
      path: 'activities',
      value: 'activities',
      total: `${stat?.activities?.total}`,
      price: `${stat?.activities?.amount}`
    },
    {
      name: 'Disbursements',
      path: 'disbursements',
      value: 'disbursements',
      total: `${stat?.disbursement?.total}`,
      price: `${stat?.disbursement?.amount}`
    },
    {
      name: 'Earnings',
      path: 'earnings',
      value: 'earnings',
      total: `${stat?.earnings?.total}`,
      price: `${stat?.earnings?.amount}`
    },
    {
      name: 'History',
      path: 'history',
      value: 'history',
      total: `${stat?.history?.total}`,
      price: `${stat?.history?.amount}`
    }
  ];

  return (
    <>
      <PageHeader title="Financing" subtitle="View and manage financing" />
      {canReadLenders || isASuperAdmin ? (
        <LendersView>
          <TabView>
            {tabs.map((item) => (
              <div
                key={item.name}
                className={`tab ${tab === item.value ? 'active' : ''}`}
                onClick={() => setSearchParams(`tab=${item.path}`)}>
                <button className={`btn ${tab === item.value ? 'active' : ''}`}>{item.name}</button>

                <div className="items">
                  {stats.isFetching ? <p>0 items</p> : <p>{item?.total} items</p>}
                  {/* <p>{item?.total} items</p> */}
                  {stats.isFetching ? (
                    <p>₦00.00</p>
                  ) : (
                    <p>₦{numberWithCommas(item?.price || '00.00')}</p>
                  )}
                </div>
              </div>
            ))}
          </TabView>
          <TabContent>{tab === 'table' && <LendersTabs />}</TabContent>
          <TabContent>{tab === 'activities' && <LenderActivities />}</TabContent>
          <TabContent>{tab === 'disbursements' && <LenderDisbursement />}</TabContent>
          <TabContent>{tab === 'earnings' && <LenderEarnings />}</TabContent>
          <TabContent>{tab === 'history' && <LenderHistory />}</TabContent>
        </LendersView>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

const LendersView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 80px;
  overflow-x: hidden;
  margin-left: 18%;
`;

const TabView = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  z-index: 100;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    text-align: start;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }

    .btn {
      border: none;
      padding-top: 12px;
      padding-bottom: 7px;
      padding-left: 10px;
      padding-right: 60px;
      background-color: transparent;
      font-size: 1rem;
      font-weight: 500;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: ${(props) => props.theme?.colors?.secondary};
      }
    }

    .items {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      padding-right: 40px;
      padding-left: 10px;
      padding-bottom: 5px;

      &:hover {
        color: ${(props) => props.theme?.colors?.secondary};
      }

      p {
        font-size: 0.875rem;
      }
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
`;

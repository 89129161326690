import withAuthentication from '@/hooks/withAuthentication'
import React from 'react'
import styled from 'styled-components';
import ProvidersTable from './ProvidersTable';

const Providers = () => {
  return (
    <ProviderView>
      <TitleView>
        <h3>Providers</h3>
        <p>
          Switch between providers to enable users have seamless transactions and payments even during downtime
        </p>
      </TitleView>

      <ProvidersTable />
    </ProviderView>
  );
}

export default withAuthentication(Providers);

const ProviderView = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const TitleView = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    max-width: 50%;
  }
`;
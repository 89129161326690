import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input';
import Select from 'react-select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createLenderTerms, fetchLenders, fetchOptions } from '@/api/lendersApi';
import Button from '@/components/Button';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

const schema = yup.object({
  finance_type: yup.string().required('Finance type is required'),
  tenure: yup.number().required('Tenure is required'),
  interest: yup
    .number()
    .typeError('Interest must be a number')
    .min(0, 'Interest must be at least 0%')
    .max(100, 'Interest cannot exceed 100%')
    .required('Interest is required'),
  // lenders: yup
  //   .array()
  //   .min(1, 'Please select at least one lender')
  //   .required('Please select a lender')
});

export const CreateTermsModal = ({ showModal, setShowModal }) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleCreateTerm = useMutation({
    mutationFn: (data) => createLenderTerms(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['lenderTerms']);
      reset();
      setShowModal(false);
      toast.success('Lenders Term Successfully Created');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  })

  const lenders = useQuery({
    queryKey: ['lenders', { page: 1, limit: 100 }],
    queryFn: fetchOptions
  })

  const lender = lenders?.data?.data?.users;

  const lenderOptions =
    lender?.map((item) => ({
      value: item?.lenderId,
      label: item?.company
    })) || [];



  const onSubmit = (data) => {
    handleCreateTerm.mutate(data);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <CreateTermsView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <Content>
              <div className="header">
                <CloseIcon
                  onClick={() => {
                    reset();
                    setShowModal(false);
                  }}
                />
              </div>

              <FormView onSubmit={handleSubmit(onSubmit)}>
                <Title>Create Lender's Terms</Title>

                {/* Financing Type */}
                <Section>
                  <Label>Finance type</Label>
                  <Options>
                    <Option>
                      <Radio
                        type="radio"
                        // id="INVOICE_FINANCE"
                        value="INVOICE_FINANCE"
                        {...register('finance_type')}
                      />
                      <OptionLabel htmlFor="INVOICE_FINANCE">Invoice Discounting</OptionLabel>
                    </Option>
                    <Option>
                      <Radio
                        type="radio"
                        // id="PURCHASE_FINANCE"
                        value="PURCHASE_FINANCE"
                        {...register('finance_type')}
                      />
                      <OptionLabel htmlFor="PURCHASE_FINANCE">Purchase Financing</OptionLabel>
                    </Option>
                  </Options>
                  <Error>{errors.finance_type?.message}</Error>
                </Section>

                {/* Tenure */}
                <Section>
                  <Label>Tenure</Label>
                  <Options>
                    <Option>
                      <Radio type="radio" id="30days" value={30} {...register('tenure')} />
                      <OptionLabel htmlFor="30days">30 days</OptionLabel>
                    </Option>
                    <Option>
                      <Radio type="radio" id="45days" value={45} {...register('tenure')} />
                      <OptionLabel htmlFor="45days">45 days</OptionLabel>
                    </Option>
                    <Option>
                      <Radio type="radio" id="60days" value={60} {...register('tenure')} />
                      <OptionLabel htmlFor="60days">60 days</OptionLabel>
                    </Option>
                  </Options>
                  <Error>{errors.tenure?.message}</Error>
                </Section>

                {/* Interest */}
                <Section>
                  <Label>Set Interest</Label>
                  <Input
                    width="100%"
                    height="60px"
                    type="text"
                    placeholder="%"
                    {...register('interest')}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                  <Error>{errors.interest?.message}</Error>
                </Section>

                {/* Multi-Select Dropdown */}
                {/* <Section>
                  <Label>Select a lender</Label>
                  <Controller
                    name="lenders"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={lenderOptions}
                        isMulti
                        isSearchable
                        placeholder="Search Lender"
                        menuShouldScrollIntoView={false}
                        menuPlacement="auto"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '60px',
                            borderColor: '#EFF0F7',
                            borderRadius: '5px',
                            border: '1px solid #EFF0F7',
                            backgroundColor: '#EFF0F7',
                            padding: '4px'
                          }),
                          menu: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          option: (base, { isFocused, isSelected }) => ({
                            ...base,
                            backgroundColor: isFocused
                              ? '#EFF0F7'
                              : isSelected
                                ? '#d3e0ea'
                                : 'white',
                            color: isFocused || isSelected ? '#000' : '#333',
                            cursor: 'pointer'
                          })
                        }}
                      />
                    )}
                  />
                  <Error>{errors.lenders?.message}</Error>
                </Section> */}

                <div className="btn">
                  <Button className="approve" type="submit">
                    {handleCreateTerm.isLoading ? (
                      <Oval height={30} width={30} color="#fff" secondaryColor="#ddd" />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </FormView>
            </Content>
          </motion.div>
        </CreateTermsView>
      )}
    </AnimatePresence>
  );
};

const CreateTermsView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    padding: 20px;
    border-radius: 8px;
    min-width: 45vw;
    /* min-height: calc(100vh - 100px); */
  }
`;

const FormView = styled.form`
  width: 500px;
  margin-top: 30px;
  margin-bottom: 50px;

  .btn {
    margin-top: 40px;
    
    .approve {
      width: 30%;
      border-radius: 18px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Section = styled.div`
width: 100%;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const Options = styled.div`
  display: flex;
  gap: 1rem;
`;

const Option = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
`;

const OptionLabel = styled.label`
  /* width: 100%; */
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Radio = styled.input`
  /* width: 20%; */
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #27ae60;
  }
`;

const Error = styled.p`
  font-size: 0.875rem;
  color: #e74c3c;
  margin-top: 0.25rem;
`;

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import CurrencyyInput from '@/components/CurrencyyInput';
import { recordPayment } from '@/api/billFinancingApi';
import { numberWithCommas } from '@/utils/helpers';
import { walletReconciliation } from '@/api/walletApi';
import Input from '@/components/Input';
import { updateProvider } from '@/api/providersApi';

function UpdateProviderModal({ showModal, setShowModal, selectedItem }) {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState('');

  const handleUpdateProvider = useMutation({
    mutationFn: ({id, data}) => updateProvider(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['providers']);
      setStatus('');
      setShowModal(false);
      toast.success('You have successfuly switched providers');
    },
    onError: (error) => {
      setStatus('');
      setShowModal(false);
      toast.error(error?.response?.data?.message);
    }
  });

  console.log(selectedItem)

  const handleSubmit = () => {
    const data = {
      status: status,
    };

    console.log(data);
    handleUpdateProvider.mutate({ id: selectedItem?.id, data});
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <button
                  type="button"
                  onClick={() => {
                    setStatus('');
                    setShowModal(false);
                  }}>
                  <CloseIcon />
                </button>
              </div>
              <ContentView>
                <div className="content">
                  <div className="align">
                    <h3>Update Provider</h3>
                  </div>

                  <DataView>
                    <InputView>
                      <LabelView>
                        <label>Status</label>
                      </LabelView>

                      <select
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}>
                        <option value="">Select a status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </InputView>
                  </DataView>

                  <div className="btn">
                    <button
                      type="button"
                      className="continue"
                      onClick={() => {
                        setStatus('');
                        setShowModal(false);
                      }}>
                      No, Cancel
                    </button>
                    <button type="button" className="activate" onClick={() => handleSubmit()}>
                      {handleUpdateProvider.isLoading ? (
                        <Oval height={20} width={20} color="#c2bdbd" secondaryColor="#ddd" />
                      ) : (
                        'Update Provider'
                      )}
                    </button>
                  </div>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default UpdateProviderModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 40vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .error {
      margin: 0.45rem 0;
      margin-left: -3rem;
      text-align: start;
      color: ${(props) => props.theme.colors?.error};

      .strong {
        font-weight: 500;
      }
    }

    .icon {
      path {
        stroke: ${(props) => props.theme.colors?.error_200};
      }
    }

    .green-icon {
      path {
        stroke: ${(props) => props.theme.colors?.warning};
      }
    }

    .align {
      width: 100%;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: ${(props) => props.theme.colors?.secondary};
        margin-top: 10px;
        text-align: start;
      }

      p {
        padding-top: 1rem;
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors?.darkGray};
        margin-bottom: 20px;
        line-height: 19px;
      }
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: #fdebeb;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    .currency-input {
      width: 100%;
      height: 64px;
      outline: none;
      margin-top: 5px;
      font-size: 1rem;
      font-weight: 300;
      text-indent: 15px;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

      &:focus {
        background-color: ${({ theme }) => theme.colors?.white};
        border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
      }

      &:disabled {
        background-color: #f2f2f2;
        color: #999999;
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors?.placeholder};
        font-size: 1rem;
        font-weight: 300;
      }
    }

    .btn {
      width: 100%;
      gap: 1rem;
      display: flex;
      align-items: center;

      .activate {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.primary};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 500;
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border: 1px solid ${(props) => props.theme.colors?.info};
        height: 64px;
        border-radius: 16px;
        color: ${(props) => props.theme.colors?.info};
        font-weight: 500;
      }
    }
  }
`;

const DataView = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${({ theme }) => theme.colors?.white};

  .disabled {
    background-color: #f2f2f2;
    color: #999999;
  }

  .check {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0;
    gap: 1rem;

    .radio-input {
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  position: relative;

  .input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
  }

  /* .currency-input {
    width: 100%;
    height: 64px;
    outline: none;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-indent: 15px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    &:focus {
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #999999;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 1rem;
      font-weight: 300;
    }
  } */

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: none;
    outline: none;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    padding: 0 20px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
  }

  .icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    position: absolute;
    top: 2.85rem;
    right: 12px;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  }

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;

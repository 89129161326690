import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LenderActivity } from './details/LenderActivity';
import { LenderInflows } from './details/LenderInflows';
import { LenderRevenue } from './details/LenderRevenue';
import { LendersHistory } from './details/LendersHistory';
import LendersTable from './LendersTable';
import { LendersTerms } from './terms/LendersTerms';
import { AssignedLenders } from './AssignedLenders';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const LendersTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  let view = searchParams.get('view');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      // setSearchParams('tab=table&view=all');
      setSearchParams({ tab: 'table', view: 'lenders-table' });
      // Optionally, you can also navigate to the new URL
      navigate('?tab=table&view=lenders-table');
    }
  }, [searchParams, navigate, setSearchParams]);

  const tabs = [
    {
      label: 'Lenders',
      path: 'view=lenders-table',
      value: 'lenders-table'
    },
    {
      label: "Lender's Terms",
      path: 'view=lenders-terms',
      value: 'lenders-terms'
    },
    {
      label: 'Assigned Lenders',
      path: 'view=assigned-lenders',
      value: 'assigned-lenders'
    }
  ];

  return (
    <Tab>
      <TabView>
        {tabs.map((item, index) => (
          <button
            key={item.path}
            className={`tabs ${view === item.value ? 'active' : ''}`}
            onClick={() => setSearchParams({ tab: 'table', view: item.value })}>
            {item.label}
          </button>
        ))}
      </TabView>
      <TabContent>
        {/* {tabs[activeTab].content} */}
        {view === 'lenders-table' && <LendersTable />}
        {view === 'lenders-terms' && <LendersTerms />}
        {view === 'assigned-lenders' && <AssignedLenders />}
      </TabContent>
    </Tab>
  );
};

const Tab = styled.div`
  width: 100%;
  margin-top: 0px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TabView = styled.div`
  height: 50px;
  display: flex;
  z-index: 10;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authenticated: false,
  user: null,
  selected: null,
  page: 1,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authenticated = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    clearSelected: (state) => {
      state.selected = null;
    },
    setPage: (state, action) => {state.page = action.payload},
    resetAppStateOnLogout: () => {},
  },
});

export const {
  resetAppStateOnLogout,
  setAuth,
  setUser,
  setPage,
  setSelected
} = appSlice.actions;
export default appSlice.reducer;
export const appSelector = (state) => state.app;

import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input';
import Select from 'react-select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateLenderTerms, fetchOptions } from '@/api/lendersApi';
import Button from '@/components/Button';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

const schema = yup.object({
  finance_type: yup.string().required('Finance type is required'),
  tenure: yup.number().required('Tenure is required'),
  interest: yup
    .number()
    .typeError('Interest must be a number')
    .min(0, 'Interest must be at least 0%')
    .max(100, 'Interest cannot exceed 100%')
    .required('Interest is required'),
  // lenders: yup
  //   .array()
  //   .min(1, 'Please select at least one lender')
  //   .required('Please select a lender')
});

export const UpdateTermsModal = ({ showModal, setShowModal, termData }) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const defaultLenderValues =
    termData?.lendersTerms?.map(({ lender }) => ({
      value: lender?.id,
      label: lender?.user?.companies?.[0]?.name || 'Unknown Company'
    })) || [];

  const [selectedLenders, setSelectedLenders] = useState(defaultLenderValues);

  useEffect(() => {
    if (termData) {
      Object.entries(termData).forEach(([key, value]) => {
        setValue(key, value);
      });
      setValue('finance_type', termData.type);
      setSelectedLenders(defaultLenderValues);
      setValue('lenders', defaultLenderValues);
    }
  }, [termData, setValue]);

  const handleUpdateTerm = useMutation({
    mutationFn: (data) => updateLenderTerms(termData.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['lenderTerms']);
      // reset();
      setShowModal(false);
      toast.success('Lender Term Successfully Updated');
    },
    onError: (error) => {
      toast.error(error?.message);
    }
  });

  const lenders = useQuery({
    queryKey: ['lenders', { page: 1, limit: 100 }],
    queryFn: fetchOptions
  });

  const lender = lenders?.data?.data?.users;

  const lenderOptions =
    lender?.map((item) => ({
      value: item?.lenderId,
      label: item?.company
    })) || [];

  // const availableLenderOptions = lenderOptions.filter(
  //   (option) => !selectedLenders.some((selected) => selected.value === option.value)
  // );

  // const availableLenderOptions = useMemo(() => {
  //   return lenderOptions.filter(
  //     (option) => !selectedLenders.some((selected) => selected.value === option.value)
  //   );
  // }, [lenderOptions, selectedLenders]);

  // const availableLenderOptions = useMemo(() => {
  //   return lenderOptions.filter(
  //     (option) => !selectedLenders.some((selected) => selected.value === option.value)
  //   );
  // }, [lenderOptions, selectedLenders]);

  // const availableLenderOptions = useMemo(() => {
  //   // Extract all selected lenderIds
  //   const selectedLenderIds = selectedLenders.map((lender) => lender.value);

  //   console.log('Selected Lender IDs:', selectedLenderIds);
  //   // Filter out lenders whose ID exists in selectedLenderIds
  //   return lenderOptions.filter((option) => !selectedLenderIds.includes(option.id));
  // }, [lenderOptions, selectedLenders]);

  const availableLenderOptions = useMemo(() => {
    const selectedLenderIds = selectedLenders.map((lender) => lender.value); // Use `value`

    console.log('Selected Lender IDs:', selectedLenderIds);

    return lenderOptions.filter((option) => !selectedLenderIds.includes(option.value)); // Compare with `value`
  }, [lenderOptions, selectedLenders]);


  console.log('Available Lender Options:', availableLenderOptions);

  // const defaultLenderValues =
  //   termData?.lendersTerms?.map(({ lender }) => ({
  //     value: lender?.id,
  //     label: lender?.user?.companies?.[0]?.name || 'Unknown Company'
  //   })) || [];

  // const onSubmit = (data) => {
  //   const transformedData = {
  //     ...data,
  //     lenders: data.lenders.map((lender) => lender.value), // Extract lender IDs
  //     removeLenders: termData.lenders.filter(
  //       (id) => !data.lenders.some((lender) => lender.value === id)
  //     ) // Determine lenders to remove
  //   };
  //   console.log(transformedData);
  //   // handleUpdateTerm.mutate(data);
  // };

  const onSubmit = (data) => {
    const currentLenders = termData?.lendersTerms?.map(({ lender }) => lender?.id) || [];

    const removeLenders = currentLenders.filter(
      (id) => !data.lenders.some((lender) => lender.value === id)
    );

    const updateData = {
      ...data,
      removeLenders,
      lenders: data.lenders.map((lender) => lender.value) // Extract IDs from selected lenders
    };

    handleUpdateTerm.mutate(updateData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <UpdateTermsView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <Content>
              <div className="header">
                <CloseIcon
                  onClick={() => {
                    // reset();
                    setShowModal(false);
                  }}
                />
              </div>

              <FormView onSubmit={handleSubmit(onSubmit)}>
                <Title>Update Lender's Terms</Title>

                {/* Financing Type */}
                <Section>
                  <Label>Finance type</Label>
                  <Options>
                    <Option>
                      <Radio type="radio" value="INVOICE_FINANCE" {...register('finance_type')} />
                      <OptionLabel htmlFor="INVOICE_FINANCE">Invoice Discounting</OptionLabel>
                    </Option>
                    <Option>
                      <Radio type="radio" value="PURCHASE_FINANCE" {...register('finance_type')} />
                      <OptionLabel htmlFor="PURCHASE_FINANCE">Purchase Financing</OptionLabel>
                    </Option>
                  </Options>
                  <Error>{errors.finance_type?.message}</Error>
                </Section>

                {/* Tenure */}
                <Section>
                  <Label>Tenure</Label>
                  <Options>
                    <Option>
                      <Radio type="radio" value={30} {...register('tenure')} />
                      <OptionLabel>30 days</OptionLabel>
                    </Option>
                    <Option>
                      <Radio type="radio" value={45} {...register('tenure')} />
                      <OptionLabel>45 days</OptionLabel>
                    </Option>
                    <Option>
                      <Radio type="radio" value={60} {...register('tenure')} />
                      <OptionLabel>60 days</OptionLabel>
                    </Option>
                  </Options>
                  <Error>{errors.tenure?.message}</Error>
                </Section>

                {/* Interest */}
                <Section>
                  <Label>Set Interest</Label>
                  <Input
                    width="100%"
                    height="60px"
                    type="text"
                    placeholder="%"
                    {...register('interest')}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                  <Error>{errors.interest?.message}</Error>
                </Section>

                {/* Multi-Select Dropdown */}
                {/* <Section>
                  <Label>Select a lender</Label>
                  <Controller
                    name="lenders"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={availableLenderOptions}
                        // options={lenderOptions}
                        isMulti
                        isSearchable
                        value={selectedLenders}
                        // defaultValue={defaultLenderValues}
                        placeholder="Search Lender"
                        menuShouldScrollIntoView={false}
                        menuPlacement="auto"
                        onChange={(newSelected) => {
                          const uniqueLenders = newSelected.filter(
                            (lender, index, self) =>
                              index === self.findIndex((t) => t.value === lender.value)
                          );

                          field.onChange(uniqueLenders);
                          // field.onChange(newSelected); // Update react-hook-form value
                          setSelectedLenders(newSelected); // Update local state
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '60px',
                            borderColor: '#EFF0F7',
                            borderRadius: '5px',
                            border: '1px solid #EFF0F7',
                            backgroundColor: '#EFF0F7',
                            padding: '4px'
                          }),
                          menu: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          option: (base, { isFocused, isSelected }) => ({
                            ...base,
                            backgroundColor: isFocused
                              ? '#EFF0F7'
                              : isSelected
                                ? '#d3e0ea'
                                : 'white',
                            color: isFocused || isSelected ? '#000' : '#333',
                            cursor: 'pointer'
                          })
                        }}
                      />
                    )}
                  />
                  <Error>{errors.lenders?.message}</Error>
                </Section> */}

                <div className="btn">
                  <Button className="approve" type="submit">
                    {handleUpdateTerm.isLoading ? (
                      <Oval height={30} width={30} color="#fff" secondaryColor="#ddd" />
                    ) : (
                      'Update Term'
                    )}
                  </Button>
                </div>
              </FormView>
            </Content>
          </motion.div>
        </UpdateTermsView>
      )}
    </AnimatePresence>
  );
};

const UpdateTermsView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    padding: 20px;
    border-radius: 8px;
    min-width: 45vw;
    /* min-height: calc(100vh - 100px); */
  }
`;

const FormView = styled.form`
  width: 500px;
  margin-top: 30px;
  margin-bottom: 50px;

  .btn {
    margin-top: 40px;

    .approve {
      width: 30%;
      border-radius: 18px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const Options = styled.div`
  display: flex;
  gap: 1rem;
`;

const Option = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
`;

const OptionLabel = styled.label`
  /* width: 100%; */
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Radio = styled.input`
  /* width: 20%; */
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #27ae60;
  }
`;

const Error = styled.p`
  font-size: 0.875rem;
  color: #e74c3c;
  margin-top: 0.25rem;
`;

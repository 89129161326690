import { ReactComponent as DashIcon } from '@/assets/icons/dash.icon.svg';
import { ReactComponent as BillsIcon } from '@/assets/icons/bills.icon.svg';
import { ReactComponent as CardIcon } from '@/assets/icons/card.icon.svg';
import { ReactComponent as ComplianceIcon } from '@/assets/icons/compliance.icon.svg';
import { ReactComponent as UsersIcon } from '@/assets/icons/users.icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/profile.icon.svg';
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings.icon.svg';
import { ReactComponent as TransferIcon } from '@/assets/icons/transfer.icon.svg';
import { ReactComponent as SubscribeIcon } from '@/assets/icons/subscribe.icon.svg';
import { ReactComponent as AccountIcon } from '@/assets/icons/account.icon.svg';
import { ReactComponent as BillIcon } from '@/assets/icons/bill.icon.svg';
import { ReactComponent as InvoiceIcon } from '@/assets/icons/invoice.icon.svg';
import { ReactComponent as LeaderboardIcon } from '@/assets/icons/leaderboard.icon.svg';
import { ReactComponent as WebhookIcon } from '@/assets/icons/webhooks.icon.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/icons/archive.icon.svg';
import { ReactComponent as LenderIcon } from '@/assets/icons/cash.icon.svg';
import { ReactComponent as MetricIcon } from '@/assets/icons/metrics.icon.svg';

export const routes = [
  {
    groupName: 'Administration',
    routes: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        mainPath: '/dashboard',
        Icon: DashIcon,
        label: null
      },
      {
        name: 'Metrics',
        path: '/metrics',
        mainPath: '/metrics',
        Icon: MetricIcon,
        label: null
      },
      {
        name: 'Bills',
        path: '/bills',
        mainPath: '/bills',
        Icon: BillsIcon,
        label: null
      },
      {
        name: 'Purchase Financing',
        path: '/purchase-financing',
        mainPath: '/purchase-financing',
        Icon: BillIcon,
        label: null
      },
      {
        name: 'Invoices',
        path: '/invoices',
        mainPath: '/invoices',
        Icon: BillsIcon,
        label: null
      },
      {
        name: 'Invoice Discounting',
        path: '/invoice-discount',
        mainPath: '/invoice-discount',
        Icon: InvoiceIcon,
        label: null
      },
      {
        name: 'Archives',
        path: '/archives',
        mainPath: '/archives',
        Icon: ArchiveIcon,
        label: null
      },
      {
        name: 'Transactions',
        path: '/transactions',
        mainPath: '/transactions',
        Icon: CardIcon,
        label: null
      },
      {
        name: 'Leaderboard',
        path: '/leaderboard',
        mainPath: '/leaderboard',
        Icon: LeaderboardIcon,
        label: null
      },
      {
        name: 'Subscriptions',
        path: '/subscriptions',
        mainPath: '/subscriptions',
        Icon: SubscribeIcon,
        label: null
      },
      {
        name: 'Wallets',
        path: '/wallets',
        mainPath: '/wallets',
        Icon: CardIcon,
        label: null
      },
      {
        name: 'Transfers',
        path: '/transfers',
        mainPath: '/transfers',
        Icon: TransferIcon,
        label: null
      },
      {
        name: 'Virtual Accounts',
        path: '/virtual-accounts',
        mainPath: '/virtual-accounts',
        Icon: AccountIcon,
        label: null
      },
      {
        name: 'Users',
        path: '/users',
        mainPath: '/users',
        Icon: UsersIcon,
        label: null
      },
      {
        name: 'Financing',
        path: '/lenders',
        mainPath: '/lenders',
        Icon: LenderIcon,
        label: null
      },
      {
        name: 'Compliance',
        path: '/compliance',
        mainPath: '/compliance',
        Icon: ComplianceIcon,
        label: null
      },
      {
        name: 'Webhooks',
        path: '/webhooks',
        mainPath: '/webhooks',
        Icon: WebhookIcon,
        label: null
      }
    ]
  },
  {
    groupName: 'Customization',
    routes: [
      {
        name: 'Profile',
        path: '/profile',
        mainPath: '/profile',
        Icon: ProfileIcon,
        label: null
      },
      {
        name: 'Settings',
        path: '/settings?',
        mainPath: '/settings',
        Icon: SettingsIcon,
        label: null
      }
    ]
  }
];

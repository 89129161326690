import { fetchLendersTerms } from '@/api/lendersApi';
import { useQuery } from '@tanstack/react-query';
import React from 'react'
import styled from 'styled-components';
import { TermsEmptyState } from './TermsEmptyState';
import { LendersTermsTable } from './LendersTermsTable';

export const LendersTerms = () => {
  const lenderTerms = useQuery({
    queryKey: ['lenderTerms'],
    queryFn: fetchLendersTerms
  });

  const terms = lenderTerms?.data?.data;

  return (
    <TermsView>
      {terms?.length === 0 ? (
        <TermsEmptyState />
      ) : (
        <LendersTermsTable />
      )}
    </TermsView>
  )
}

const TermsView = styled.div`

`;